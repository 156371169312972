<template>
  <div class="body-page">
    <header class="fasteadHeader d-flex justify-content-around">
      <img
        src="../../assets/images/nova-logo-fastead-completo.png"
        alt="logo-fastead"
        class="my-2"
      />
      <img
        src="../../assets/images/concurseiro-logo-2.png"
        alt="logo-concurseiro"
        class="my-2"
      />
    </header>
    <section class="d-flex flex-column justify-content-center text-center">
      <div>
        <h1>Escolha a melhor forma de pagamento</h1>
      </div>
      <div>
        <div class="border border-primary rounded my-2 py-2">
          <h4>Cartão de crédito</h4>
          <p>Técnico em informática em até 2x</p>
          <p>2x R$1000,00</p>
          <a href="https://sandbox.asaas.com/c/jrc9cemmfs23q2rp"
            >QUERO ME MATRICULAR</a
          >
        </div>
        <div class="border border-primary rounded my-2 py-2">
          <h4>PIX</h4>
          <p>Técnico em informática</p>
          <p>R$100,00</p>
          <a href="https://sandbox.asaas.com/c/2t0pffj4c3tgkua7"
            >QUERO ME MATRICULAR</a
          >
        </div>
        <div class="border border-primary rounded my-2 py-2">
          <h4>Boleto</h4>
          <p>Técnico em informática</p>
          <p>R$200,00</p>
          <a href="https://sandbox.asaas.com/c/bfkv3ebxk2g7dwrs"
            >QUERO ME MATRICULAR</a
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import methods from "@/methods";
import { settings } from "@/config";

export default {
  name: "LandingPageRedirect",
  mixins: [methods],
  data: function () {
    return {};
  },
  created() {},
  mounted() {
    this.$store.state.fastCarregando = false;
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
.body-page {
  min-height: 65vh;
}
.fasteadHeader {
  background-color: #e5eef4;
  height: 5rem;
}
a {
  background-color: #5cb85c;
  border: none;
  border-radius: 16px;
  transition: .5s;
  padding: .5em 1em;
  color: #FFF;
  text-decoration: none;
}
a:hover {
  opacity: .5;
}
</style>
